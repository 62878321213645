#journey {
	button {
		margin-top: 10px;
   		margin-right: 5px;
	}

	.text-area {
		margin-bottom: 30px;
	}

	.pic-area {

		text-align: center;
		margin-bottom: 50px;

		img {
			width: 100%;
			max-width: 450px;
		}
	}
}