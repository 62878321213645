/* -------- Sticky Footer -------- */

/*@font-face {
    font-family: 'amaticbold';
    src: url('../../fonts/amatic-bold-webfont.woff2') format('woff2'),
         url('../../fonts/amatic-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/
@font-face {
    font-family: 'amaticbold';
    src: url('../../fonts/amatic-bold-webfont.eot');
    src: url('../../fonts/amatic-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/amatic-bold-webfont.woff2') format('woff2'),
         url('../../fonts/amatic-bold-webfont.woff') format('woff'),
         url('../../fonts/amatic-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*@font-face {
    font-family: 'ralewaysemibold';
    src: url('../../fonts/raleway-semibold-webfont.woff2') format('woff2'),
         url('../../fonts/raleway-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/
@font-face {
    font-family: 'ralewaysemibold';
    src: url('../../fonts/raleway-semibold-webfont.eot');
    src: url('../../fonts/raleway-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/raleway-semibold-webfont.woff2') format('woff2'),
         url('../../fonts/raleway-semibold-webfont.woff') format('woff'),
         url('../../fonts/raleway-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
 
/*@font-face {
    font-family: 'ralewayextralight';
    src: url('../../fonts/raleway-extralight-webfont.woff2') format('woff2'),
         url('../../fonts/raleway-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/

/*@font-face {
    font-family: 'ralewaylight';
    src: url('../../fonts/raleway-light-webfont.woff2') format('woff2'),
         url('../../fonts/raleway-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/
@font-face {
    font-family: 'ralewaylight';
    src: url('../../fonts/raleway-light-webfont.eot');
    src: url('../../fonts/raleway-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/raleway-light-webfont.woff2') format('woff2'),
         url('../../fonts/raleway-light-webfont.woff') format('woff'),
         url('../../fonts/raleway-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'black_jackregular';
    src: url('../../fonts/blackjack-webfont.eot');
    src: url('../../fonts/blackjack-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/blackjack-webfont.woff') format('woff'),
         url('../../fonts/blackjack-webfont.ttf') format('truetype'),
         url('../../fonts/blackjack-webfont.svg#black_jackregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gloss-and-bloomregular';
    src: url('../../fonts/gloss-and-bloom-webfont.eot');
    src: url('../../fonts/gloss-and-bloom-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/gloss-and-bloom-webfont.woff2') format('woff2'),
         url('../../fonts/gloss-and-bloom-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ralewayregular';
    src: url('../../fonts/Raleway-Regular.eot');
    src: url('../../fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/Raleway-Regular.woff2') format('woff2'),
         url('../../fonts/Raleway-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html {
  position: relative;
  min-height: 100%;
}
body {

  /* Margin bottom by footer height */
  margin-bottom: 110px;
  font-family: 'ralewayregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;

  h1 {
  	/*font-size: 80px !important;
  	font-family: 'amaticbold';*/
  	font-family: 'ralewaysemibold';
  	/*font-size: 2em;*/
  	font-size: 28px;
  }

  h2 {
  	font-family: 'ralewaysemibold';
  	font-size: 1.4em;
  }

  h3 {
  	font-family: 'ralewaysemibold';
  	/*margin-top: 40px;*/
  	font-size: 1.4em;
  }

  h4 {
  	font-family: 'ralewaylight';
  }

  p {
  	font-family: 'ralewaylight';
  	font-size: 1.15em;
  	color: black;
  }

  a, a:hover, a:active, a:focus {
  	outline: 0;
  	text-decoration: none;
  }
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 91px;
  background-color: #231f20;
  font-size: 0.8em;
}

/* -------- Sticky Footer END -------- */

/* -------- Custom Styling -------- */

h1, h2, h3, h4 {
	white-space: normal;
}

.container {
	/*padding-bottom: 50px;*/

	&.no-jumbotron {

		margin-top: @header-height-mobile;

		@media(min-width:@screen-sm-min){
			margin-top: @header-height;
		}
	}
}

.btn-more {
	border: 1px solid black;
    background: none;
    padding: 10px 20px;
    border-radius: 0;
    margin: 15px 0 0;

    &.btn-slider {
    	border-color: white;
    	color: white;
    }

    &.btn-post-sibling {
    	margin: 20px 10px 30px;

    	@media(max-width:@screen-xs-max){
			margin-left: 7px;
			margin-right: 7px;
		}
    }
}

.btn:focus,.btn:active {
   outline: none;
}


footer {
	.row {

		.content-left {

			@media(max-width:@screen-xs-max){
				text-align: center;
				margin-top: 10px;
				/*margin-bottom: -10px;*/
			}

			@media(min-width:@screen-sm-min){
				text-align: left;
				margin-top: 40px;
			}
		}

		.content-center {
			text-align: center;

			@media(min-width:@screen-sm-min){
				margin-top: 40px;
			}
		}

		.content-right {

			@media(max-width:@screen-xs-max){
				text-align: center;
			}

			@media(min-width:@screen-sm-min){
				text-align: right;
				margin-top: 40px;
			}
		}

		a {
			text-decoration: none;

		}

		a:hover, a:active, a:focus {
			text-decoration: none;
			color: #777777;
		}

		.bommeli {
			text-decoration: none;

			&a:hover {
				text-decoration: none;
				color: #777777;
			}
		}

		.socialbutton {
			&.pinterest {
				height: 21px;
				background-position-y: 5px;
			}
		}
	}
}

.jumbotron {
	width: 100%;
	background: #ffffff no-repeat center center;

	/*@media(min-width:@screen-sm-min){
		margin-top: @header-height;
	}*/

	margin-top: @header-height-mobile;

	@media(min-width:@screen-sm-min){
		margin-top: @header-height;
	}

	/*background: url(../../img/bay-of-fires.jpg) no-repeat center center;*/
	height: 450px;

	@media(max-width: @screen-xs-max){
		max-height: 300px;
	}

	background-size:cover;
	padding-bottom: 0;

	.page-header{
		border: none;
	}

	.label {
		text-align: center;

		h1{
			margin-top: 100px;

			@media(max-width: @screen-xs-max){
				margin-top: 50px;
				font-size: 36px;
			}
		}
	}

	&.empty {
		height: 0;
		padding-top: 0;
		background: none !important;
	}

	h1 {
		font-family: 'gloss-and-bloomregular';
	}
}

#posts {
	.teaser {
		> div {
			margin-bottom: 20px;
		}
	}
}

.teaser {
	> div {
		text-align: center;
	}

	.teaser-image {
		width: 100%;
	}

	.menu-teaser {
		img {
			width: 100%;
		}
	}
}

.jumbotron-slider {

	@media(min-width:@screen-sm-min){
		margin-top: @header-height;
	}

	/*height: 450px;*/

	/*@media(max-width:@screen-xs-max){
		height: 500px;
	}*/

	.carousel-inner {
		height: 100%;
	}

	.jumbotron {
		margin-top: 0 !important;

		@media(max-width:@screen-xs-max){
			margin-top: 50px !important;
		}
	}

	.arrow-left {
		position: absolute;
		left: 20px;
		width: 100%;
		height: 100%;
		background: url('../../img/prev.png') no-repeat center left;

		@media(max-width:@screen-xs-max){
			left: 0;
			height: 400px;
    		margin-top: 50px;
		}
	}

	.arrow-right {
		position: absolute;
		right: 20px;
		width: 100%;
		height: 100%;
		background: url('../../img/next.png') no-repeat center right;

		@media(max-width:@screen-xs-max){
			right: 0;
			height: 400px;
    		margin-top: 50px;
		}
	}
}

.container.container-teaser {

	/*@media (min-width: @screen-sm-min){
	    width: 750px;
	}*/

	padding-top: 50px;

	@media (min-width: @screen-sm-min){
	    width: 580px;
	}

	@media (min-width: @screen-md-min){
	    width: 720px;
	}

	@media (min-width: @screen-lg-min){
	    width: 870px;
	}

	.teaser-image-container {
		position: relative;
	}

	.helper {
		display: inline-block;
	    height: 100%;
	    vertical-align: middle;
	}

	.teaser-image-container {

		a {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;

			.darkener, .teaser-text {

				-o-transition:.3s;
				-ms-transition:.3s;
				-moz-transition:.3s;
				-webkit-transition:.3s;
				transition:.3s;
			}

			.darkener {
				background: black;
				opacity: 0.5;

				display:inline-block;
				position:absolute;
				top:70%;
				left:0;
				width:100%;
				height:30%;
			}

			.teaser-text {

				display: flex;
			    justify-content: center;
			    align-items: center;
			    width: 100%;
			    height: 30%;
			    position: absolute;
			    bottom: 0;
			    color:white;
				font-size:16px;
				padding: 0 5px;
			}
		}

		&:hover, &:active, &:focus {
			.darkener {
				top:0;
				height:100%;
			}

			.teaser-text {
				height: 90%;
			}
		}
	}
}

.navbar-inverse {

	.navbar-nav {

		font-weight: bold;

		a {
			text-transform: uppercase;
			padding-top: 5px;
			font-size: 0.95em;
		}

		> .active{

			> a {
				color: #01b195;
				background: none;
			}

			> a:hover {
				color: #01b195;
				background: none;
			}

			> a:focus {
				color: #01b195;
				background: none;
			}
		}
	}

	.navbar-brand {
		img {

			width: 250px;
			margin-top: 30px;

			@media(max-width:@screen-xs-max){
				width: 125px;
				margin-top: 0px;
			}

			@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
				width: 175px;
				margin-top: 38px;
			}
		}
	}
}

nav {
	padding-top: 100px;
	margin-top: -100px;
}

footer {
	padding-bottom: 100px;
	/*margin-bottom: -200px;*/
}

ul.nav a:hover { color: #01b195 !important; }

@media(min-width:@screen-sm-min){

	.navbar-inner {
    	min-height: 0px;
	}

	.navbar-brand,
	.navbar-nav li a {

	    line-height: @header-height;
	    height: @header-height;
	    padding-top: 10px;
	}
}

.blog-entry {

	.blog-cal {
		position: relative;
		left: -20px;
		width: 100%;
		max-width: 68px;
		margin-top: 20px;

		@media(max-width:@screen-sm-max){
			left: 0;
		}

	}

	.blog-map {
		position: relative;
		top: -100px;
		width: 100%;
	}
}

.polaroid-section {

	margin: 40px -5px;

	.col-sm-4 {
		padding-left: 5px;
		padding-right: 5px;
	}


	.polaroid-container {
		/*position: relative;*/

		.polaroid-background-container {

			position: relative;
			text-align: center;
			max-width: 300px;
			margin: 0 auto;

			img.polaroid-background {
				width: 100%;
			}

			img.polaroid-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 84%;
				margin-left: 8%;
				margin-top: 14%;

				&.middle {
					transform:          rotate(0.5deg);
					-ms-transform:      rotate(0.5deg);
					-moz-transform:     rotate(0.5deg);
					-webkit-transform:  rotate(0.5deg);
					-o-transform:       rotate(0.5deg);
				}

				&.right {
					transform:          rotate(-0.5deg);
					-ms-transform:      rotate(-0.5deg);
					-moz-transform:     rotate(-0.5deg);
					-webkit-transform:  rotate(-0.5deg);
					-o-transform:       rotate(-0.5deg);
				}
			}
		}
	}
}

.grid {
	img {
		width: 100%;
		padding: 0 10px 0;
		/*padding-bottom: 20px;*/
		position: relative;
	}

	img.big {
		/*padding-bottom: 40px;*/
		/*padding-bottom: 0px;
		margin-bottom: 20px;*/
	}
}
.grid-sizer, .grid-item { 

	width: 25%;

	@media(max-width:@screen-sm-max){
		width: 50%;
	}
}
.grid-item {
	/*padding: 10px;*/
}
.grid-item--width2 { width: 50%; }
.grid-item--height2 { height: 200px; }

.post-button-navigate {

	padding: 0 15px;

	a {
		text-decoration: none;
		color: #717171;
	}

	a:hover {
		text-decoration: none;
	}
}

.journey-image {
	max-width: 700px;
	display: block;
	margin: 0 auto;
}

#list-countries a, button {
	display: inline-block;
	border: 1px solid black;
	background: none;
	padding: 10px 20px;
	margin: 10px 5px;

	&.active{
		background-color: #E6E6E6;
	}

	&:active, &:focus {
		outline: none;
	}

	&:disabled {
		opacity: 0.4;
	}
}

#newsletter-registration {
	margin: 30px 15px 0;

	.form-element {
		padding-right: 30px;
	}

	input {
		height: 42px;
	}

	button {
		margin-right: 0;
	}

	.form-row {
		margin-top: 15px;
	}

	.button {
		text-align: right;
	}

	.error {
		border-color: red;
	}

	.second-element {
		@media(max-width:479px){
			margin-top: 15px;
		}
	}
}

#posts {
	padding-bottom: 40px;
}


/* -------- Custom Styling END -------- */

/* -------- Scroll to top -------- */

.scrollToTop {

	width:56px; 
	height:50px;
	/*padding:10px; */
	text-align:center; 
	/*background: red;*/
	/*font-weight: bold;*/
	/*color: #444;*/
	text-decoration: none;
	position:fixed;
	bottom: 15%;
	right: 0;
	display:none;
	background: url('../../img/scroll-to-top-arrow.png') no-repeat top left;

	@media(max-width:@screen-xs-max){
		bottom: 0;
	}

	&:hover {
		text-decoration:none;
	}
}

/* -------- Scroll to top END -------- */
/* -------- Image Map -------- */
img.map, map area {
    outline: none;
}
/* -------- Image Map END -------- */