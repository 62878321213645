/*!
 * Lightbox for Bootstrap 3 by @ashleydw
 * https://github.com/ashleydw/lightbox
 *
 * License: https://github.com/ashleydw/lightbox/blob/master/LICENSE
 */

.ekko-lightbox-container {
    position: relative;
}
.ekko-lightbox-nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%
}
.ekko-lightbox-nav-overlay a {
    z-index: 100;
    display: block;
    width: 49%;
    height: 100%;
    font-size: 30px;
    color: #fff;
    text-shadow: 2px 2px 4px #000;
    opacity: 0;
    filter: dropshadow(color=#000000, offx=2, offy=2);
    -webkit-transition: opacity .5s;
    -moz-transition: opacity .5s;
    -o-transition: opacity .5s;
    transition: opacity .5s

    outline: 0;

    &:hover, &:active, &:focus {
    	outline: 0;
    }
}
.ekko-lightbox-nav-overlay a:empty {
    width: 49%
}
.ekko-lightbox a:hover {
    text-decoration: none;
    opacity: 1;
}
/*.ekko-lightbox .glyphicon-menu-left {*/
.ekko-lightbox .icon-arrow-left {
    left: 0;
    float: left;
    padding-left: 15px;
    text-align: left;
    background: url("/Frontend/img/prev.png") no-repeat left center;
}
/*.ekko-lightbox .glyphicon-menu-right {*/
.ekko-lightbox .icon-arrow-right {
    right: 0;
    float: right;
    padding-right: 15px;
    text-align: right;
    background: url("/Frontend/img/next.png") no-repeat right center;
}
.ekko-lightbox .modal-footer {
    text-align: left
}

/* Dark Theme */
.modal-backdrop.in {
    -webkit-opacity: 0.95;
    -moz-opacity: 0.95;
    opacity: 0.95;
    background-color: #111111;
}
.ekko-lightbox .modal-content {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
    background-color: transparent;
}
.ekko-lightbox .modal-header {
    border: 0;
    padding-left: 0;
    padding-right: 0;
}
.ekko-lightbox .modal-header .close {
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
    color: #fff;
    text-shadow: 0;
    font-weight: 100;
    margin-top: 5px;

    /*position: absolute;
    top: 50px;
    right: 5px;
    z-index: 999;*/
}
.ekko-lightbox .modal-header .close:hover {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
}
.ekko-lightbox .modal-header h4.modal-title {
    font-weight: 100;
    color: #fff;
    padding: 0;
}
.ekko-lightbox .modal-body {
    padding: 0;
    padding-bottom: 20px;
}
.ekko-lightbox .modal-footer {
    -webkit-opacity: 0.9;
    -moz-opacity: 0.9;
    opacity: 0.9;
    border: 0;
    color: #fff;
    font-weight: 100;
    padding: 0;
}
.ekko-lightbox-nav-overlay a {
    -webkit-opacity: 0.9;
    -moz-opacity: 0.9;
    opacity: 0.9;
    text-shadow: none;
}

.ekko-lightbox .glyphicon {
	text-decoration: none;
	font-size: 2em;
	font-weight: lighter;
}

.modal.in .modal-dialog {
	margin: auto;
}

.grid {
    margin-top: 20px;
    margin-bottom: 30px;

    img {

        @media(max-width:@screen-xs-max){
            padding: 0 5px 0;
        }

        @media (min-width: @screen-xs-min) and (max-width: @screen-md-max) {
            padding: 0 10px 0;
        }

        @media(min-width:@screen-md-min){
            padding: 0 5px 0;
        }
    }
}

/* Make Fullscreen images vertically centered */
.modal {
  text-align: center;
}

.modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " "; 
    height: 90%;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}



/* Horizon Gallery */

#gallery {
    padding-bottom: 40px;
}

.gallery-title-container {

    text-align: left;
    margin: 30px 0 10px;

    h2, h3, h4 {
        display: inline;
    }

    img {
        margin-bottom: 5px;
        height: 15px;
    }
}

.grid {

}

#filtered-content {

    h2, h3, h4 {
        margin-right: 10px;
    }

    h1 {
        margin-top: 50px;
    }
}

/* Unite gallery overrides */
.ug-textpanel-title {
    text-align: center !important;
    font-family: 'ralewaylight';
}

.ug-lightbox-numbers {
    font-family: 'ralewaylight';
}

.ug-textpanel {
    overflow: visible;
}

#list-countries a {
    color: black;
}

.grid:after {
    content: '';
    display: block;
    clear: both;
}

@media screen and (max-width: 991px) {
    .grid-sizer, .grid-item {
      width: 48%;
    }
}

@media screen and (min-width: 992px) {
    .grid-sizer, .grid-item {
      width: 31%;
    }
}

.grid-item img {
    padding: 0;
    display: block;
    width: 100%;
    margin-bottom: 4%;
}

.gutter-sizer { width: 10px; }

.grid-item {
    float: left;
    /*margin-bottom: 10px;*/
}

/*** Pano ***/
.grid-item--pano img {
    padding: 0;
    display: block;
}

@media screen and (max-width: @screen-xs-max) {
    .grid-item--pano {
      width: 100%;
    }

    .grid-item--pano img {
        width: 98%;
        margin-bottom: 2%;
    }
}

@media screen and (min-width: @screen-sm) and (max-width: @screen-sm-max) {
    .grid-item--pano {
      width: 100%;
    }

    .grid-item--pano img {
        width: 97.9%;
        margin-bottom: 2%;
    }
}

@media screen and (min-width: @screen-md) and (max-width: @screen-md-max) {
    .grid-item--pano {
      width: 63.4%;
    }

    .grid-item--pano img {
        width: 100%;
        margin-bottom: 2.5%;
    }
}

@media screen and (min-width: @screen-lg) {
    .grid-item--pano {
      width: 63.2%;
    }

    .grid-item--pano img {
        width: 100%;
        margin-bottom: 2.5%;
    }
}