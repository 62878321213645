/* -------- Blog Calendar Icon -------- */
time.icon
{
  font-family: 'ralewaylight';
  font-size: 0.7em; /* change icon size */
  display: block;
  position: relative;
  width: 7em;
  height: 7em;
  background-color: #fff;
  margin: 2em auto;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(-10deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;

  @media(max-width:@screen-sm-max){
    margin-bottom: 30px;
  }

  @media(min-width:@screen-md-min){
    margin-top: 30px;
    margin-left: -20px;
  }

  @media(min-width:@screen-lg-min){
    margin-left: -10px;
  }
}

time.icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.icon strong
{
  position: absolute;
  top: 0;
  padding: 0.2em 0;
  color: #fff;
  background-color: #888888;
  box-shadow: 0 2px 0 #888888;
  font-size: 1.2em;
}

time.icon em
{
  position: absolute;
  bottom: 0.3em;
  color: #333333;
  font-size: 1.2em;
}

time.icon span
{
  width: 100%;
  font-size: 2.2em;
  letter-spacing: -0.05em;
  padding-top: 1.1em;
  color: #2f2f2f;
}

time.icon:hover, time.icon:focus
{
  /*-webkit-animation: swing 0.6s ease-out;
  animation: swing 0.6s ease-out;*/
}

@-webkit-keyframes swing {
  0%   { -webkit-transform: rotate(0deg)  skewY(0deg); }
  20%  { -webkit-transform: rotate(12deg) skewY(4deg); }
  60%  { -webkit-transform: rotate(-9deg) skewY(-3deg); }
  80%  { -webkit-transform: rotate(6deg)  skewY(-2deg); }
  100% { -webkit-transform: rotate(0deg)  skewY(0deg); }
}

@keyframes swing {
  0%   { transform: rotate(0deg)  skewY(0deg); }
  20%  { transform: rotate(12deg) skewY(4deg); }
  60%  { transform: rotate(-9deg) skewY(-3deg); }
  80%  { transform: rotate(6deg)  skewY(-2deg); }
  100% { transform: rotate(0deg)  skewY(0deg); }
}

time {

  
}

/* -------- Blog Calendar Icon  END -------- */