#home {
	padding-bottom: 100px;

	.jumbotron {
		h1 {
			margin-top: 30px;

			@media (max-width: @screen-xs-max) {
				margin-top: 10px;
			}
		}

		h2 {
			text-align: center;
		}
	}

	.container-teaser {

		.home {
			padding-left: 0.9%;
			padding-right: 0.9%;
		}

		.teaser {
			margin-left: -5px;
			margin-right: -5px;
		}
	}
}

#posts-teaser {
	margin-top: 40px;
	margin-bottom: 150px;

	@media (max-width: @screen-xs-max) {
		margin-top: 0;
	}
}

#home-gallery-teaser-big, #home-gallery-teaser-small {

	position: relative;
	/*padding-bottom: 100px;*/
	@media (max-width: @screen-xs-max) {
		padding-bottom: 50px;
	}

	.text-box {
		position: absolute;
		top: 0;
		left: 14%;
		width: 30%;

		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			top: -3%;
			left: 14%;
			width: 35%;
		}
	}

	.gallery-col-1 {
		padding-top: 36%;
	}

	.gallery-col-2 {
		padding-top: 19.8%;
	}

	.gallery-col-4 {
		padding-top: 10.8%;
	}

	.second-entry {
		padding-top: 10%;
	}

	img {
		width: 100%;
	}

	.col-xs-3 {
		padding-left: 1%;
		padding-right: 1%;
	}

	#button-container {

		position: relative;
		margin-top: 10%;
		/*margin-left: 5%;*/
		text-align: center;

		@media (max-width: @screen-xs-max) {
			position: relative;
			top: 0;
			left: 0;

			margin-top: 5%;
		}

		button {
			margin: 0;
		}

		#arrow {
			position: absolute;
		    top: 110%;
		    left: 40%;
		    width: 40%;

		    img {
		    	width: 50%;
		    }

		    @media (max-width: @screen-xs-max) {
				position: absolute;
			    top: 120%;
			    left: 33%;

			    img {
			    	width: 20%;
			    }
			}

			@media (max-width: @screen-xs-min) {
			    left: 20%;
			}
		}

		#click-teaser {
			position: absolute;
		    top: 40%;
		    left: 90%;
		    width: 260%;
		    text-align: left;

		    @media (max-width: @screen-xs-max) {
				position: absolute;
			    top: 20%;
			    left: 65%;
			    width: 180px;
			    text-align: left;
			}
		}
	}
}

.home-gallery-teaser {
	margin-bottom: 100px;
}

#home-gallery-teaser-small {
	#centered-container {
      width: 100%;
      overflow: hidden;
      text-align: center;
    }

    #centered-content {
      width: 150%;
      margin-left: -25%;
      background-color: red;
    }
}

#home-about-us {

	margin-bottom: 50px;
	padding-bottom: 50px;

	@media (max-width: @screen-xxs-max) {
		.desktop{
			display: none;
		}
	}

	@media (min-width: @screen-xs-min) {
		.mobile {
			display: none;
		}
	}

	.part-left, .part-text-mobile {
		background-color: #f1efee;
		padding: 20px;
	}

	.part-left {
		position: relative;
		width: 60%;
	}

	.part-right {
		width: 40%;
		height: 350px;

		.content {
			max-width: 100%;
			height: 100%;
			background: no-repeat center;
		}
	}

	.part-picture-mobile, .part-button-mobile {
		text-align: center;
		padding: 0;
		margin-bottom: 20px;

		.content {
			max-width: 100%;
			height: 100%;
			background: no-repeat center;
		}
	}

	.part-picture-mobile {
		height: 350px;
		background-color: gray;
	}

	.container {
		display: flex;
		align-items: center;
	}

	button {
		position: absolute;
		bottom: -60px;
		left: 0;
		margin: 0;

		@media (max-width: @screen-xxs-max) {
			position: relative;
			bottom: 0;
		}
	}
}