.about {

	.polaroid {
		margin-top: 50px;
		width: 100%;
		max-width: 240px;
	}

	.center-max-md {
		@media(max-width:@screen-md-max){
			text-align: center;
		}
	}

	.text {
		padding-top: 50px;

		@media(min-width:@screen-sm-min){
			padding-right: 80px;
		}

		
	}

	.quote {
		.quote-text {

			position: relative;
			font-family: 'black_jackregular';
			font-size: 2em;
			line-height: 35px;
			margin-top: 80px;

			@media(min-width:@screen-md-min){
				margin-top: 190px;
			}

			.quote-start {
				display: block;
				position: absolute;
				top: -8px;
				left: -42px;
			}

			.quote-end {
				margin-left: -8px;
			}
		}

		.quote-teller {
			margin-top: 20px;
			font-size: 0.9em;
		}

		@media(max-width:@screen-sm-max){
			margin: 0 20%;
		}
	}

	.btn-contact {

		text-align: center;

		a {
			text-decoration: none;
			color: #000;

			margin: 20px auto 20px;

			@media(max-width:@screen-sm-max){
				margin-top: 40px;
			}
		}

		a:hover, a:active, a:focus {
			text-decoration: none;
			color: #000;
		}
	}
}

