.styleelement-twocolumnpics {

	margin: 30px 0;
	display: flex;

	.left {
		padding: 0;
	}

	.right {
		padding-right: 0;
		padding-left: 10px;
	}

	img {
		width: 100%;
		height: auto;
		vertical-align: middle;
	}
}