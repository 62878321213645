#continent-map {

	height: 0;
	padding-top: 48%;
	position: relative;
	margin-top: 30px;

	svg {
		position: absolute;
		top: 0;
		left: 0;
	}

	path {
		fill: #E6E6E6;

		&.has-entries {
			fill: #c1c0c0;
		}
	}

	.active {fill:#999 !important;}

	path.has-entries {
		cursor: pointer;
	}

	path:active, path:focused, path:visited {
		fill: #E6E6E6 !important;
	}

	path.has-entries:hover, path.has-entries:active, path.has-entries:focused, path.has-entries:visited {
		fill: #999 !important;
		cursor: pointer !important;
	}
}

#continent-map-filtering {

	margin-top: 80px;
	/*margin-bottom: 30px;*/

	#list-countries {

		

		#list-countries-loader {
			margin-left: 10px;
		}
	}

	#arrow {
		position: absolute;
	    left: 55%;
	    top: -50px;

		img {
			width: 30%;

			/*@media(max-width:@screen-xs){
				width: 60%;
			}*/
		}

		#click-teaser {
		    /*position: absolute;
			top: 60%;
			left: 90%;
			width: 100%;*/

			position: absolute;
		    top: 60%;
		    right: 100%;
		    width: 130%;

		    @media (max-width: @screen-xs-max) {
				width: 120%;
			}
		}
	}
}

#filtered-content {

	padding-bottom: 30px;
}